import React, { useEffect, useMemo } from "react"
import groupBy from "lodash/groupBy"
import { Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SimpleTable from "../components/common/tables/simple"
import { fetchGamesAction, gameSelector, GameState } from "../hasura/slices/game"
import { getMedal, postSlackMessage, queryParams, shootConfetti } from "../lib/helpers"
import { userSelector, UserState } from "../hasura/slices/user"
import { usePrevious } from "../hooks/usePrevious"

export default function CompletedGame() {
  const dispatch = useDispatch()

  const { accessToken, user, isTeacher }: UserState = useSelector(userSelector)
  const { games }: GameState = useSelector(gameSelector)

  const previousUser = usePrevious(user)

  // @ts-ignore
  const game = games.find(({ id }) => id === queryParams()?.id)

  const participantColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Points",
        accessor: "score",
      },
    ],
    []
  )

  useEffect(() => {
    if (!user || !game || !isTeacher || previousUser) return

    postSlackMessage(
      `${user.display_name} completed multiplayer game ${game.root?.display_name.toUpperCase()} (scores: ${game.positions
        .map((p) => p.score)
        .sort()
        .join(", ")})`
    )
  }, [user, previousUser, game])

  useEffect(() => {
    if (game) shootConfetti()
  }, [game])

  useEffect(() => {
    const teacherId = isTeacher ? user?.id : user?.classroom?.teacher_id
    if (!accessToken || !teacherId) return

    setTimeout(() => fetchGames(teacherId), 5000)
  }, [accessToken, user])

  const fetchGames = (teacherId: string) => {
    dispatch(fetchGamesAction(teacherId))
  }

  const grouped = groupBy(game?.positions, "score")

  return (
    <Layout>
      <SEO title="Completed Game" />

      <h1 className="main-header pt-4">Leaderboard</h1>

      {game ? (
        <div className="m-0-auto text-left" style={{ maxWidth: "500px" }}>
          <SimpleTable
            columns={participantColumns}
            data={Object.keys(grouped)
              .map((g) => parseInt(g, 10))
              .sort((a, b) => a - b)
              .reverse()
              .map((score, idx) => {
                const names = grouped[score].map((g) => g.user.display_name)
                const medal = getMedal(idx) || `${idx + 1}. `
                const result = names.length > 1 ? `${names.join(", ")} (tie)` : names[0]
                return { score, name: medal + result }
              })}
          />
        </div>
      ) : (
        <div className="mt-3 flex-center">
          <p className="mb-0 text-s bold gray8 garamond font-italic mr-1">Calculating...</p>
          <Spinner size="sm" color="muted" />
        </div>
      )}
    </Layout>
  )
}
